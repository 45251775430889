<template>
	<div>
		<b-row>
			<b-col sm="12" class="mb-3">
				<i class="icon-layers"></i>&nbsp;
				<b>ASSET LOCATION OVERLAY</b>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group label="Company">
					<v-select
						name="Company"
						class="style-chooser"
						label="text"
						:options="companyOptions"
						:reduce="(company) => company.value"
						v-model="filterBy.company"
						v-validate="'selectRequired'"
					>
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a company
							</em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group label="Storage Location">
					<v-select
						name="Storage Location"
						class="style-chooser"
						label="text"
						:options="storageLocations"
						:reduce="(loc) => loc.value"
						v-model="filterBy.storageLocation"
						v-validate="'selectRequired'"
					>
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a location
							</em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group label="Asset Category">
					<v-select
						name="Asset Category"
						class="style-chooser"
						label="text"
						:options="assetTypeCategoryOptions"
						:reduce="(category) => category.value"
						v-model="filterBy.assetTypeCategory"
						v-validate="'selectRequired'"
					>
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a category
							</em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
		</b-row>

		<template v-if="!currentLocation.location">
			<b-alert v-if="currentLocation.isGettingLocation" show>
				Getting your location...
			</b-alert>
			<b-alert v-if="currentLocation.error" variant="danger" show>
				Sorry, but the following error occurred:
				<strong>{{ currentLocation.error }}</strong>
			</b-alert>
		</template>
		<template v-else>
			<gmap-map
				:center="center"
				:zoom="12"
				:style="{ width: '100%', height: '400px' }"
				:options="mapConfig"
			>
				<gmap-marker
					v-for="(marker, index) in markers"
					:key="index"
					:position="marker.position"
					:clickable="true"
					@click="toggleInfoWindow(index)"
				>
					<gmap-info-window
						@closeclick="closeInfoWindow(index)"
						:opened="marker.visible"
					>
						<b class="font-weight-bold">{{ marker.label.text }}</b>
						<br />
						Total Assets:
						{{
							!marker.assetDistribution.total
								? 0
								: marker.assetDistribution.total
						}}
						<br />
						<span v-show="marker.assetDistribution.total > 0">
							Distribution
							<br />
							<ul>
								<li
									v-for="(assetType, index) in marker.assetDistribution
										.assetTypes"
									:key="index"
								>
									<strong
										>{{ assetType.assetType }}:
										{{ assetType.perAssetTypeTotalCount }}</strong
									>
									<ul>
										<li>
											Tagged:
											{{ assetType.perAssetTypeTaggedCount }}
										</li>
										<li>
											Untagged:
											{{
												assetType.perAssetTypeTotalCount -
												assetType.perAssetTypeTaggedCount
											}}
										</li>
									</ul>
								</li>
							</ul>
						</span>
					</gmap-info-window>
				</gmap-marker>
			</gmap-map>
			Search Results: {{ assetLocationFilteredOptions.length }}
		</template>
	</div>
</template>

<script>
import config from '@/config/env-constants';
import _ from 'lodash';

export default {
	name: 'google-map',
	props: {
		assetLocationOverlayStr: {
			type: String,
			required: true,
		},
		companyOptions: {
			type: Array,
			required: true,
		},
		storageLocationOptions: {
			type: Array,
			required: true,
		},
		assetTypeCategoryOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			center: {},
			markers: [],
			filterBy: {
				company: config.companyDefaultValue,
				storageLocation: config.storageLocationDefaultValue,
				assetTypeCategory: null,
			},
			mapConfig: {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false,
			},
			currentLocation: {
				isGettingLocation: false,
				location: null,
				error: null,
			},
			assetLocationOverlay: null,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			isSuperAdmin: this.$store.getters.isSuperAdmin,
		};
	},
	computed: {
		assetLocationFilteredOptions() {
			let data = this.getAssetLocationOptions();

			if (this.filterBy.storageLocation.id) {
				data = data.filter(
					(x) =>
						x.connectedStorageLocationId === this.filterBy.storageLocation.id
				);
			}
			if (this.filterBy.assetTypeCategory) {
				data = _.filter(data, {
					assetTypes: [{ category: this.filterBy.assetTypeCategory }],
				});
			}

			return data;
		},
		storageLocations() {
			let data = [];

			if (this.filterBy.company.id) {
				const defaultSelection = [
					{
						value: config.storageLocationDefaultValue,
						text: ' - Please select - ',
					},
				];
				data = [
					...defaultSelection,
					...this.storageLocationOptions.filter(
						(x) => x.value.companyId === this.filterBy.company.id
					),
				];
			} else {
				data = this.storageLocationOptions;
			}

			return data.sort();
		},
		getCurrentCompanyId() {
			return JSON.parse(localStorage.getItem('loggedUser')).companyId;
		},
	},
	watch: {
		assetLocationFilteredOptions: {
			handler(value) {
				this.loadMarkers(value);
			},
			immediate: true,
		},
		['filterBy.company.id']: {
			handler(companyId) {
				this.filterBy.storageLocation = config.storageLocationDefaultValue;
				if (companyId) {
					this.$nextTick(
						() => (this.center = this.setPinOnCenterView(this.markers))
					);
				}
			},
			immediate: true,
		},
	},
	mounted() {
		if (!_.isEmpty(this.assetLocationOverlayStr)) {
			this.assetLocationOverlay = JSON.parse(this.assetLocationOverlayStr);
		}

		this.findMyLocation();
	},
	methods: {
		getAssetLocationOptions() {
			let data = [];

			if (!_.isEmpty(this.assetLocationOverlay)) {
				data = this.assetLocationOverlay;

				if (this.filterBy.company.id) {
					data = this.assetLocationOverlay.filter(
						(x) => x.connectedCompanyId === this.filterBy.company.id
					);
				}
			}

			return data;
		},
		loadMarkers(markers) {
			// reset all markers
			this.markers = [];

			if (markers && !_.isEmpty(markers)) {
				for (const marker of markers) {
					this.addMarker(marker);
				}
			}

			this.closeAllInfoWindows();
		},
		addMarker(marker) {
			let geoaddress = marker.geoaddress;

			let geolocation = {
				lat: geoaddress._latitude,
				lng: geoaddress._longitude,
			};

			this.markers.push({
				position: geolocation,
				assetDistribution: marker,
				visible: false,
				label: {
					text: marker.connectedStorageLocation,
					color: '#4285F4',
					fontSize: '12px',
					fontWeight: 'bold',
				},
			});

			this.center = geolocation;
		},
		toggleInfoWindow(index) {
			let prevValue = this.markers[index].visible;
			this.markers[index].visible = !prevValue;
		},
		closeInfoWindow(index) {
			this.toggleInfoWindow(index);
		},
		closeAllInfoWindows() {
			for (const marker of this.markers) {
				if (marker.visible) marker.visible = false;
			}
		},
		setPinOnCenterView(markers, companyId) {
			let centerMarkerCoordinates = {};
			for (const item of markers) {
				let currDistribution = item.assetDistribution;

				if (this.isSuperAdmin) {
					if (
						currDistribution.companyId === currDistribution.connectedCompanyId
					) {
						centerMarkerCoordinates = {
							lat: currDistribution.geoaddress._latitude,
							lng: currDistribution.geoaddress._longitude,
						};

						break;
					} else {
						continue;
					}
				} else {
					let lat = parseFloat(currDistribution.geoaddress._latitude);
					let lng = parseFloat(currDistribution.geoaddress._longitude);
					if (companyId) {
						if (companyId === currDistribution.connectedCompanyId) {
							centerMarkerCoordinates = { lat, lng };
							break;
						} else continue;
					} else {
						if (
							currDistribution.companyId === currDistribution.connectedCompanyId
						) {
							centerMarkerCoordinates = { lat, lng };
							break;
						} else continue;
					}
				}
			}

			return centerMarkerCoordinates;
		},
		async findMyLocation() {
			this.currentLocation.isGettingLocation = true;
			try {
				this.currentLocation.isGettingLocation = false;
				this.currentLocation.location = await this.$getCurrentLocation();
				const lat = parseFloat(this.currentLocation.location.lat);
				const lng = parseFloat(this.currentLocation.location.lng);
				this.center = { lat, lng };

				if (this.markers.length > 0 && this.getCurrentCompanyId) {
					this.center = this.setPinOnCenterView(
						this.markers,
						this.getCurrentCompanyId
					);
				}
			} catch (error) {
				this.currentLocation.isGettingLocation = false;

				switch (error.code) {
					case error.PERMISSION_DENIED:
						this.currentLocation.error =
							'User denied the request for Geolocation.';
						break;
					case error.POSITION_UNAVAILABLE:
						this.currentLocation.error =
							'Location information is unavailable. Please try to enable your Location Settings.';
						break;
					case error.TIMEOUT:
						this.currentLocation.error =
							'The request to get user location timed out. Please try to enable your Location Settings.';
						break;
					case error.UNKNOWN_ERROR:
						this.currentLocation.error = 'An unknown error occurred.';
						break;
				}
			}
		},
	},
};
</script>